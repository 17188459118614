var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pb-0",attrs:{"id":"ricercheOnDemandForm"}},[_c('div',[_c('v-row',{staticClass:"white red--text-juranews pt-3 pl-10 text-h4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-spacer')],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"3"}},[_vm._v("Ricerche on demand")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-spacer')],1)],1)],1),_c('v-form',{staticClass:"grey darken-3 pt-6",attrs:{"id":"form"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome","error-messages":errors,"dark":""},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Cognome","error-messages":errors,"dark":""},model:{value:(_vm.cognome),callback:function ($$v) {_vm.cognome=$$v},expression:"cognome"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ragione Sociale","error-messages":errors,"dark":""},model:{value:(_vm.ragioneSociale),callback:function ($$v) {_vm.ragioneSociale=$$v},expression:"ragioneSociale"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PIVA","error-messages":errors,"dark":""},model:{value:(_vm.piva),callback:function ($$v) {_vm.piva=$$v},expression:"piva"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Indirizzo","error-messages":errors,"dark":""},model:{value:(_vm.indirizzo),callback:function ($$v) {_vm.indirizzo=$$v},expression:"indirizzo"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Numero","error-messages":errors,"dark":""},model:{value:(_vm.numero),callback:function ($$v) {_vm.numero=$$v},expression:"numero"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Cap","error-messages":errors,"dark":""},model:{value:(_vm.cap),callback:function ($$v) {_vm.cap=$$v},expression:"cap"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","type":"email","error-messages":errors,"dark":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',{staticClass:"pb-4"},[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{staticClass:"grey--text text--lighten-2",attrs:{"cols":"4"}},[_vm._v(" Letta l'informativa Privacy fornita del Titolare, acconsento ad essere aggiornato tramite e-mail, in merito alle iniziative e le novità offerte da JuraNews. (la \"Newsletter\"). ")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2","md":"2"}},[_c('v-btn',{staticClass:"red text-h6 elevation-0 rounded-0 mt-2",attrs:{"width":"200","dark":""},on:{"click":function($event){return _vm.send()}}},[_vm._v(" INVIA ")])],1),_c('v-col',{attrs:{"cols":"3"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }