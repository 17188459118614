













import { Component, Prop, Vue } from "vue-property-decorator";
import JnRicercaOnDemandForm from "@/views/ricercheondemand/RicercaOnDemandForm.vue";

@Component({
  components: {
    JnRicercaOnDemandForm
  },
})
export default class JnRicercaOnDemand extends Vue {
  nome = "";
  cognome = "";
  ragioneSociale = "";
  piva = "";
  indirizzo = "";
  numero = "";
  cap = "";
  email = "";

  send() {
    console.log("SEND")
  }
}
