




















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class JnRicercaOnDemandForm extends Vue {
  nome = '';
  cognome = '';
  ragioneSociale = '';
  piva = '';
  indirizzo = '';
  numero = '';
  cap = '';
  email = '';

  send() {
    console.log('SEND');
  }
}
